import type {ReactElement} from 'react';

import type {OneCertificateInterfaces} from '@OneObjectInterfaces';
import type {Person} from '@refinio/one.core/lib/recipes.js';
import type {SHA256Hash, SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import {calculateIdHashOfObj} from '@refinio/one.core/lib/util/object.js';
import type {LeuteModel} from '@refinio/one.models/lib/models/index.js';
import {serializeWithType} from '@refinio/one.core/lib/util/promise.js';
import type {CertificateData} from '@refinio/one.models/lib/models/Leute/TrustedKeysManager.js';
import ProfileModel from '@refinio/one.models/lib/models/Leute/ProfileModel.js';
import type {License} from '@refinio/one.models/lib/recipes/Certificates/License.js';
import type {UnversionedObjectResult} from '@refinio/one.core/lib/storage-unversioned-objects.js';
import type {Signature} from '@refinio/one.models/lib/recipes/SignatureRecipes.js';
import type {OneCertificateTypeNames} from '@refinio/one.models/lib/recipes/Certificates/CertificateRecipes.js';

import type {
    TrustObject,
    CertificateSetting,
    IdObjectType
} from '@/components/trust/common/types.js';
import {
    GAIA_CLINIC,
    GAIA_PHYSICIAN,
    GREEN_CHECK_MARK_BUTTON,
    ROOT_OF_TRUST
} from '@/components/Constants.js';
import {
    getRightToDeclareTrustedKeysForEverybodyCertificateSettings,
    getRightToDeclareTrustedKeysForSelfCertificateSettings,
    getSignupCertificateSettings,
    getTrustKeysCertificateSettings
} from '@/components/trust/common/utils.js';
import type ClinicModel from '@/gaia/model/roles/ClinicModel.js';
import {shareHash} from '@/utils/Utils.js';
import type PhysicianModel from '@/gaia/model/roles/PhysicianModel.js';
import type AdminModel from '@/gaia/model/roles/AdminModel.js';

/**
 *
 * @param leuteModel
 * @returns
 */
export function getGaiaCertificateSettings(
    leuteModel: LeuteModel,
    adminModel: AdminModel,
    clinicModel: ClinicModel,
    physicianModel: PhysicianModel
): CertificateSetting[] {
    return [
        getAffirmationCertificateSettings(leuteModel, adminModel, clinicModel),
        getTrustKeysCertificateSettings(leuteModel.trust),
        getRightToDeclareTrustedKeysForEverybodyCertificateSettings(leuteModel.trust),
        getRightToDeclareTrustedKeysForSelfCertificateSettings(leuteModel.trust),
        getSignupCertificateSettings(leuteModel.trust),
        getPhysicianCertificateSettings(leuteModel, clinicModel, physicianModel)
    ];
}

/**
 *
 * @param leuteModel
 * @returns
 */
function getPhysicianCertificateSettings(
    leuteModel: LeuteModel,
    clinicModel: ClinicModel,
    physicianModel: PhysicianModel
): CertificateSetting {
    return {
        settingUniqueId: 'PhysicianOfClinic',
        type: 'IdObject',
        oneType: 'Person',
        certificateRecipeName: 'RelationCertificate',
        create: async (trustObject, issuer) => {
            if (trustObject.obj.$type$ !== 'Person') {
                throw Error(
                    `unable to create PhysicianOfClinic Certificate on ${trustObject.obj.$type$}`
                );
            }

            const personId = await calculateIdHashOfObj(trustObject.obj);
            const signature = await leuteModel.trust.certify(
                'RelationCertificate',
                {
                    person1: personId,
                    person2: await leuteModel.myMainIdentity(),
                    relation: 'is Physician at',
                    app: 'edda.gaia.one'
                },
                issuer
            );

            await serializeWithType(
                'SharePhysicianOfClinicRelationCertificate',
                shareCertificateData.bind(shareCertificateData, personId, signature)
            );
        },
        isCreatable: clinicModel.canCreatePhysician.bind(clinicModel),
        icon: async (trustObject: IdObjectType, certificateData: CertificateData) => {
            if (trustObject.obj.$type$ !== 'Person' || !certificateData.trusted) {
                return;
            }

            const physicianPersonId = await calculateIdHashOfObj(trustObject.obj);
            if (
                (await physicianModel.isPhysicianRelationCertificateData(
                    certificateData.certificate,
                    physicianPersonId
                )) &&
                (await physicianModel.isPhysician(physicianPersonId))
            ) {
                return GAIA_PHYSICIAN;
            }
        }
    };
}

/**
 *
 * @param trustedKeysManager
 * @returns
 */
export function getAffirmationCertificateSettings(
    leuteModel: LeuteModel,
    adminModel: AdminModel,
    clinicModel: ClinicModel
): CertificateSetting {
    return {
        oneType: '*',
        settingUniqueId: 'AffirmationCertificate',
        certificateRecipeName: 'AffirmationCertificate',
        create: async (trustObject: TrustObject, issuer?: SHA256IdHash<Person>) => {
            const certificate = await leuteModel.trust.certify(
                'AffirmationCertificate',
                {data: trustObject.hash as SHA256Hash},
                issuer
            );

            if (trustObject.type !== 'Object' || trustObject.obj.$type$ !== 'Profile') {
                return;
            }

            // if affirmation is for clinic we need to share it
            if (await adminModel.isAdmin()) {
                if (trustObject.type !== 'Object' || trustObject.obj.$type$ !== 'Profile') {
                    return;
                }
                const personId = trustObject.obj.personId;
                const profileId = await calculateIdHashOfObj(trustObject.obj);
                const profileModel = await ProfileModel.constructFromLatestVersion(profileId);
                if (clinicModel.isProfileWithOrganisationName(profileModel)) {
                    await serializeWithType(
                        'ShareAffirmationCertificate',
                        shareCertificateData.bind(shareCertificateData, personId, certificate)
                    );
                }
            }
        },
        icon: async (trustObject: TrustObject, certificateData: CertificateData) => {
            if (certificateData.trusted) {
                if (trustObject.type === 'Object' && trustObject.obj.$type$ === 'Profile') {
                    if (await clinicModel.isClinic(trustObject.obj.personId)) {
                        return GAIA_CLINIC;
                    }
                }
                return GREEN_CHECK_MARK_BUTTON;
            }
        }
    };
}

/**
 * facade for passing models
 * @param leuteModel
 * @returns
 */
export function getGaiaAdditionalIndicators(
    adminModel: AdminModel
): (trustObjects: TrustObject[]) => Promise<(ReactElement | string)[]> {
    return getAdditionalIndicators.bind(undefined, adminModel);
}

/**
 *
 * @param leuteModel
 * @returns
 */
export async function getAdditionalIndicators(
    adminModel: AdminModel,
    trustObjects: TrustObject[]
): Promise<(ReactElement | string)[]> {
    for (const trustObject of trustObjects) {
        if (
            trustObject.type === 'IdObject' &&
            trustObject.obj.$type$ === 'Person' &&
            (await adminModel.isAdmin(trustObject.hash as SHA256IdHash<Person>))
        ) {
            return [ROOT_OF_TRUST];
        }
    }
    return [];
}

/**
 *
 * @param personId
 * @param certificateData
 */
async function shareCertificateData<T extends OneCertificateTypeNames>(
    personId: SHA256IdHash<Person>,
    certificateData: {
        license: UnversionedObjectResult<License>;
        certificate: UnversionedObjectResult<OneCertificateInterfaces[T]>;
        signature: UnversionedObjectResult<Signature>;
    }
): Promise<void> {
    await shareHash(personId, certificateData.signature.hash);
    await shareHash(personId, certificateData.certificate.hash);
}
