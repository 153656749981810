export const LIGHT_GREY = 'light-grey';
export const DARK_ORANGE = 'dark-orange';
export const FILE_FORMATS = ['png', 'jpg', 'jpeg', 'webp', 'gif'];
export const WAVING_HAND = '👋';
export const CHAT = '💬';
export const GREEN_CHECK_MARK_BUTTON = '✅';
export const GAIA_PHYSICIAN = '👩‍⚕️';
export const GAIA_CLINIC = '🏥';
export const ROOT_OF_TRUST = '👑';
export const THREE_DOTS = '...';
